@font-face {
	font-family: 'Space Grotesk';
	src: url('/public/fonts/SpaceGrotesk-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Space Grotesk';
	src: url('/public/fonts/SpaceGrotesk-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Alef';
	src: url('/public/fonts/Alef-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


$primary: #000;
$secondary: #3effb5;
$gray: #f1ede5;

$transition: cubic-bezier(0.33, 1, 0.68, 1);

$base-font: 'Alef', sans-serif;
$heading-font: 'Space Grotesk', sans-serif;