@import "~the-new-css-reset/css/reset.css";
@import './variables.scss';

.container-bottles  {
	box-sizing: border-box;
	margin: 0;
	font-family: $heading-font;
	color: #fff;
	overflow-x: hidden;
}


.top-bottle {
	letter-spacing: .04em;
	padding-top: 50px;
	transition: background-color .5s $transition;

	.heading {
		text-align: center;
	}

	.surtitle {
		font-size: 1rem;
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	.titles {
		font-size: 6.25rem;
		text-transform: uppercase;
		font-weight: bold;
		position: relative;
		height: 1em;

		.title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, calc(-50% - 10px));
			opacity: 0;
			transition: opacity .5s $transition, transform .5s $transition;

			&.active {
				opacity: 1;
				transform: translate(-50%, -50%);
			}
		}

	}

	.slider-container {
		position: relative;
		transform: translateX(calc(100vw / 7 / -2));

		&::after {
			content: '';
			display: block;
			width: 100%;
			padding-bottom: 12%;
		}

		.slider {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-30%);
			display: flex;
			align-items: center;
			transition: transform 1s $transition;

			.slide {
                flex: 0 0 calc(100vw / 7);
				padding: 0 20px;
				transition: .5s ease-in-out;

				&.active {
					transform: scale(1.25);
				}
			}
		}
	}
}

.bottom-bottle {
	max-width: 1316px;
	margin: 10% auto 0;
	padding: 0 20px;

	.range-container {
		width: 100%;
		height: 8px;

		input {
			background: transparent linear-gradient(90deg, #2765CC 0%, #0F7672 8%, #9FC5BC 15%, #3EFFB5 23%, #748968 31%, #B7C697 39%, #DAE75F 46%, #DBA378 54%, #C88479 62%, #C15E4E 69%, #D1A4BA 77%, #DE7ACB 85%, #AB9ECB 92%, #9EACCC 100%) 0% 0% no-repeat padding-box;
			width: 100%;
			height: 8px;

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				background: $primary;
				cursor: pointer;
			}
		}
	}

	.btn-container {
		display: flex;
		justify-content: center;
		margin-top: 3rem;

		.btn {
			background-color: $primary;
			color: $secondary;
			height: 46px;
			line-height: 46px;
			padding: 0 20px;
		}
	}
}